import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import { format } from "date-fns"
import { sk } from "date-fns/locale";
import logo from "../img/3HFL.png"


import { useData } from "../context/DataContext";
import { useEffect } from "react";
import { useState } from "react";

function Home() {

  const { rounds, matches } = useData();
  const [now] = useState(new Date())
  const [round, setRound] = useState(new Date())
  const [selected, setSelected] = useState({})
  const [loaded, setLoaded] = useState(false)
  const [next, setNext] = useState({})
  const [res, setRes] = useState(Math.floor((round.getTime() - now.getTime()) / 1000 / 60 / 60 / 24))
  useEffect(() => {
    let dis = 9999999999999999999999999
    let m = new Date()
    rounds.forEach(e => {
      let d = new Date(e.date)
      d.setHours(23)
      let t = new Date()
      if (Math.abs(d.getTime() - t.getTime()) < dis && d.getTime() - t.getTime() > 0) {
        m = d
        dis = Math.abs(d.getTime() - t.getTime())
        setRound(d)
        setSelected(e)
        setLoaded(true)
        setNext(matches.filter(x => x.round === e.id).find(e => e.state === "planned"))
      }
    })
    setRes(Math.floor((m.getTime() - now.getTime()) / 1000 / 60 / 60 / 24))
  }, [now, rounds, matches])


  return (
    <div className="overflow-x-hidden ">
      <Navbar displaylogo={false} />
      <div className="flex flex-col md:flex-row h-screen w-screen justify-around items-center ">
        <img src={logo} alt="" className="md:w-[40vw] md:max-w-[600px] px-5" />
        <div className="hidden md:block bg-accent w-[2px] h-[25vh] "></div>
        <div className="font-bold text-5xl text-center  px-4 md:w-1/2">
          {loaded && <div className=" -mt-32 md:-mt-0 mb-20 md:mb-10">
            {res !== 0 && <span>Ďalšie kolo sa odohrá </span>}
            {(res > 1 && res < 15) && <span >o <span className="text-accent">{res}</span> </span>}
            {(res === 0) && <div>
              {matches.filter(x => x.round === selected.id && x.state === "active").length > 0 && <div>
                <span className="text-3xl">Prebiehajúci zápas:</span>
                {matches.filter(x => x.round === selected.id && x.state === "active").map((e, i) => (
                  <div key={i} className="will-change-transform justify-between  flex font-bold text-lg justify-center">
                    {i === 0 && <div key={i} className="will-change-transform  flex bg-white text-primary my-8 rounded-lg">
                      <div className={"  px-1 md:px-10 py-5  border-green-500 border-r-[6px]"}>{e.time}</div>
                      <span className="whitespace-pre px-1  md:px-10 py-5 text-center">{e.teamName[0]}</span>
                      <div className="   px-1 md:px-10 py-5 text-center relative ">{e.score[0]}:{e.score[1]}</div>
                      <span className=" whitespace-pre px-1  md:px-10 py-5 text-center ">{e.teamName[1]}</span>
                      <div className={"  px-1 md:px-10 py-5  border-primary border-l-2"}>{next.category === "young" ? "mladší" : "starší"}</div>
                    </div>}

                  </div>
                ))}

              </div>}
              {matches.filter(x => x.round === selected.id && x.state === "planned").length > 0 && <span className="text-3xl">Nasledujúci zápas:</span>}
              {matches.filter(x => x.round === selected.id && x.state === "planned").length > 0 && <div>
                {next && <div className="will-change-transform  flex font-bold text-lg justify-center">
                  <div key={next.time} className="flex justify-between bg-white text-primary mt-8 rounded-lg">
                    <div className={" px-1 md:px-10 py-5  border-primary border-r-2"}>{next.time}</div>
                    <span className=" whitespace-pre px-1 md:px-10 py-5 text-center">{next.teamName[0]}</span>

                    <div className="   px-1 md:px-10 py-5 text-center relative "> {next.score[0]}:{next.score[1]}  </div>

                    <span className="whitespace-pre   px-1 md:px-10 py-5 text-center ">{next.teamName[1]}</span>
                    <div className={"  px-1 md:px-10 py-5  border-primary border-l-2"}>{next.category === "young" ? "mladší" : "starší"}</div>
                  </div>

                </div>
                }

              </div>}
              {matches.filter(x => x.round === selected.id && (x.state === "planned" || x.state === "active")).length === 0 && <span className="">Dnešné kolo sa už skončilo!</span>}
            </div>}
            {res === 1 && <span>už <span className="text-accent"> zajtra</span>!</span>}
            {(res > 1 && res < 5) && <span>dni!</span>}
            {(res > 4 && res < 8) && <span>dní!</span>}
            {res > 7 && <span className="text-accent">{format(new Date(round), 'dd.MM.yyyy', { locale: sk })}</span>}


          </div>}
          <Link to={"/matches"} className="mt-11 bg-accent   text-primary text-2xl py-2 rounded-full px-4">{res !== 0 ? "Nasledujúce" : "Všetky"} {matches.filter(x => x.round === selected.id && (x.state === "planned" || x.state === "active")).length > 0 ? "zápasy" : "zápasy"}</Link>
        </div>
      </div>

    </div >
  );
}

export default Home;
