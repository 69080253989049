import Navbar from "../components/Navbar";
import { useData } from "../context/DataContext";


const Standings = () => {
    const { teams } = useData();
    const teamsO = teams.filter(e => e.category === "old")
    const teamsY = teams.filter(e => e.category === "young")


    return (
        <div>
            <Navbar displaylogo={true} />
            <div className="md:flex justify-around mb-20 md:mb-0 md:mt-20 p-4 md:p-0">
                <div>
                    <div className="text-bold text-2xl py-5">Kategória mladší</div>
                    <div className="table rounded-lg bg-gray-200  text-primary">
                        <div className="table-header-group  rounded-t-lg">
                            <div className="table-row p-4 font-bold text-lg text-center rounded-t-lg">
                                <div className="hidden md:table-cell pl-2">Poradie</div>
                                <div className="md:hidden table-cell pl-2">#</div>
                                <div className="table-cell px-1 md:px-4 pr-2 border-r-2 border-primary">Tím</div>
                                <div className="table-cell px-1 md:px-4 py-2">Z</div>
                                <div className="table-cell px-1 md:px-4 py-2">V</div>
                                <div className="table-cell px-1 md:px-4 py-2">R</div>
                                <div className="table-cell px-1 md:px-4 py-2">P</div>
                                <div className="table-cell px-1 md:px-4 py-2">Skóre</div>
                                <div className="table-cell px-1 md:px-4 py-2">+/-</div>


                                <div className="hidden md:table-cell px-4 py-2">Body</div>
                                <div className="md:hidden table-cell px-1 py-2">B</div>
                            </div>
                        </div>
                        <div className="table-row-group rounded-lg ">
                            {teamsY.sort((a, b) => b.points - a.points || (b.score[0] - b.score[1]) - (a.score[0] - a.score[1]) || b.score[0] - a.score[1]).map((e, i) => (
                                <div key={i} className="table-row p-4 font-bold mtext-base md:text-xl odd:bg-gray-300">
                                    <div className={"table-cell pl-2 text-center"}>{i + 1}</div>
                                    <div className="table-cell px-2  md:px-6 pr-2  border-r-2 border-primary text-center">{e.name}</div>
                                    <div className="table-cell px-1  md:px-4 py-4 text-center">{e.played} </div>
                                    <div className="table-cell px-1  md:px-4 py-4 text-center">{e.won} </div>
                                    <div className="table-cell px-1  md:px-4 py-4 text-center">{e.tie} </div>
                                    <div className="table-cell px-1  md:px-4 py-4 text-center">{e.lost} </div>
                                    <div className="table-cell px-1  md:px-4 py-4 text-center">{e.score[0]}:{e.score[1]} </div>
                                    <div className="table-cell px-1  md:px-4 py-4 text-center">{e.score[0] - e.score[1]} </div>
                                    <div className="table-cell px-1  md:px-4 py-4 text-center pr-2">{e.points} </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
                <div>
                    <div className="text-bold text-2xl py-5">Kategória starší</div>
                    <div className="table rounded-lg bg-gray-200  text-primary">
                        <div className="table-header-group  rounded-t-lg">
                            <div className="table-row p-4 font-bold text-lg text-center rounded-t-lg">
                                <div className="hidden md:table-cell pl-2">Poradie</div>
                                <div className="md:hidden table-cell pl-2">#</div>
                                <div className="table-cell px-1 md:px-4 pr-2 border-r-2 border-primary">Tím</div>
                                <div className="table-cell px-1 md:px-4 py-2">Z</div>
                                <div className="table-cell px-1 md:px-4 py-2">V</div>
                                <div className="table-cell px-1 md:px-4 py-2">R</div>
                                <div className="table-cell px-1 md:px-4 py-2">P</div>
                                <div className="table-cell px-1 md:px-4 py-2">Skóre</div>
                                <div className="table-cell px-1 md:px-4 py-2">+/-</div>


                                <div className="hidden md:table-cell px-4 py-2">Body</div>
                                <div className="md:hidden table-cell px-1 py-2">B</div>
                            </div>
                        </div>
                        <div className="table-row-group rounded-lg ">
                            {teamsO.sort((a, b) => b.points - a.points || (b.score[0] - b.score[1]) - (a.score[0] - a.score[1]) || b.score[0] - a.score[1]).map((e, i) => (
                                <div key={i} className="table-row p-4 font-bold mtext-base md:text-xl odd:bg-gray-300">
                                    <div className={"table-cell pl-2 text-center"}>{i + 1}</div>
                                    <div className="table-cell px-2  md:px-6 pr-2  border-r-2 border-primary text-center">{e.name}</div>
                                    <div className="table-cell px-1  md:px-4 py-4 text-center">{e.played} </div>
                                    <div className="table-cell px-1  md:px-4 py-4 text-center">{e.won} </div>
                                    <div className="table-cell px-1  md:px-4 py-4 text-center">{e.tie} </div>
                                    <div className="table-cell px-1  md:px-4 py-4 text-center">{e.lost} </div>
                                    <div className="table-cell px-1  md:px-4 py-4 text-center">{e.score[0]}:{e.score[1]} </div>
                                    <div className="table-cell px-1  md:px-4 py-4 text-center">{e.score[0] - e.score[1]} </div>
                                    <div className="table-cell px-1  md:px-4 py-4 text-center pr-2">{e.points} </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Standings;