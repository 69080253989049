import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar';
import { useUserAuth } from "../context/UserAuthContext";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { logIn } = useUserAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await logIn(email, password);
            navigate("/");
        } catch (err) {
            if (err.message === "Firebase: Error (auth/wrong-password).") {
                alert("Nesprávne heslo");
            } else if (err.message === "Firebase: Error (auth/user-not-found).") {
                alert("Nesprávny email");

            } else {
                alert(err.message);
            }
        }
    };

    return (
        <div className='flex flex-col w-screen h-screen justify-center items-center'>
            <Navbar />
            <form className='flex flex-col border-4 rounded-xl border-accent bg-background px-12 w-96 pb-12' onSubmit={handleSubmit}>
                <h3 className='text-2xl pt-6 pb-12 text-center'>Admin prihlásenie</h3>

                <div className='flex flex-col'>
                    <label className='text-xl'>Email</label>
                    <input
                        type='email'
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        className='bg-transparent border-b-2 border-accent focus:outline-none'
                    />

                    <label className='text-xl pt-8'>Heslo</label>
                    <input
                        type='password'
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        className='bg-transparent border-b-2 border-accent focus:outline-none'
                    />

                </div>
                <button className='bg-accent text-primary w-18 text-background rounded-md font-semibold px-4 py-2 mt-20 '>Prihlásiť</button>
            </form>
            <p>Nemáš admin účet? <Link to='/signup' className="text-accent underline">Registruj sa!</Link></p>
        </div>
    );
}

export default Login;