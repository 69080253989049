import { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { format } from "date-fns"
import { sk } from "date-fns/locale";
import WarningIcon from '@mui/icons-material/Warning';
import { useData } from "../context/DataContext";

const Matches = () => {
    const { teams, rounds, matches } = useData();
    const [selected, setSelected] = useState({ round: " ", date: new Date() })
    const [current, setCurrent] = useState({ round: " ", date: new Date() })
    useEffect(() => {
        let dis = 9999999999999999999999999999
        rounds.forEach(e => {
            let d = new Date(e.date)
            let t = new Date()

            if (Math.abs(d.getTime() - t.getTime()) < dis && d.getTime() - t.getTime() > -(24 * 60 * 60 * 1000)) {
                dis = Math.abs(d.getTime() - t.getTime())
                setSelected(e)
                setCurrent(e)
            }
        })

    }, [rounds])





    return (
        <div className="mb-20">
            <Navbar displaylogo={true} />
            <div className="mt-20 md:mt-0"></div>
            {rounds && <div className="flex w-[80vw] mx-auto justify-center items-center">
                {rounds.map((e, i) => {
                    return (
                        <div key={e.id}
                            className={
                                " rounded-lg mx-3 border-2 h-9 w-9 border-accent flex justify-center items-center cursor-pointer font-bold "
                                + ((selected.date === e.date) ? " bg-white text-primary border-0 text-lg " : " ")
                                + ((current.date < e.date && selected.date !== e.date) ? " text-accent " : " bg-accent text-primary  ")

                            }
                            onClick={f => { setSelected(e) }}
                        >
                            <div className="mx-3">{i + 1}</div>
                        </div>
                    )
                })}
            </div>}



            <div className="text-center md:text-left md:flex w-screen justify-center text-4xl mt-5">
                <h1 className="md:mr-24 mb-1 md:mb-0">{rounds.indexOf(selected) + 1}. kolo</h1>
                <h1>{format(new Date(selected.date), 'dd.MM.yyyy', { locale: sk })}</h1>
            </div>
            {/* let match = {time: matchTime, round: selectedRound.id, teams: [selectedTeam1, selectedTeam2], score: [0, 0], state: "planned", category: cat } */}
            {(teams.length > 0 && matches.length > 0) && <div className="md:flex justify-around mt-1">
                <div>
                    <div className="text-bold text-2xl py-5 px-5 md:px-0">Kategória mladší</div>
                    <div className="table rounded-lg bg-gray-200 text-primary mx-auto">
                        <div className="table-row-group rounded-lg ">
                            {matches.filter(x => x.category === "young" && x.round === selected.id).map((e, i) => (
                                <div key={i} className="table-row p-4 font-bold text-lg even:bg-gray-300">
                                    <div className={"hidden md:table-cell px-10 py-5" + ((e.state === "active") ? " border-green-500 border-r-[6px] " : " ")}>{e.time}</div>
                                    <div className="table-cell md:border-l-2 border-primary px-3 md:px-10 py-5 text-center">{e.teamName[0]}</div>
                                    <div className="table-cell md:hidden px-3 text-center ">
                                        <div className="relative -top-2">
                                            <div className={"text-xl border-b-4  " + ((e.state === "active") ? " border-green-500  " : " border-gray-500")}>{e.score[0]}:{e.score[1]}</div>
                                            <div className="text-base">{e.time}</div>
                                        </div>

                                    </div>
                                    <div className="hidden md:table-cell  px-3 md:px-10 py-5 text-center relative "> {e.score[0]}:{e.score[1]} {e.kontumacia && <span className="group"><WarningIcon /> <span className="hidden group-hover:block bg-primary text-white px-2 absolute ">Kontumácia</span> </span>} </div>

                                    <div className="table-cell  px-3 md:px-10 py-5 text-center ">{e.teamName[1]}</div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
                <div>
                    <div className="text-bold text-2xl py-5 px-5 md:px-0">Kategória starší</div>
                    <div className="table rounded-lg bg-gray-200 text-primary mx-auto">
                        <div className="table-row-group rounded-lg ">
                            {matches.filter(x => x.category === "old" && x.round === selected.id).map((e, i) => (
                                <div key={i} className={"table-row font-bold text-lg even:bg-gray-300"}>
                                    <div className={"hidden md:table-cell px-10 py-5" + ((e.state === "active") ? " border-green-500 border-r-[6px] " : " ")}>{e.time}</div>
                                    <div className="table-cell md:border-l-2 border-primary px-1 md:px-10 py-1 md:py-5 text-center">{e.teamName[0]}</div>
                                    <div className="table-cell md:hidden px-1 text-center ">
                                        <div className="relative -top-2">
                                            <div className={"text-xl border-b-4  " + ((e.state === "active") ? " border-green-500  " : " border-gray-500")}>{e.score[0]}:{e.score[1]}</div>
                                            <div className="text-base">{e.time}</div>
                                        </div>

                                    </div>
                                    <div className="hidden md:table-cell  px-3 md:px-10 py-5 text-center relative">{e.score[0]}:{e.score[1]} {e.kontumacia && <span className="group"><WarningIcon /> <span className="hidden group-hover:block bg-primary text-white px-2 absolute ">Kontumácia</span> </span>}</div>
                                    <div className="table-cell  px-3 md:px-10 py-5 text-center ">{e.teamName[1]}</div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>


            </div>}

        </div>
    );
}

export default Matches;