import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import logo from "../img/3HFL_lite_white.png"
import logo2 from "../img/3HFL.png"
import PersonIcon from '@mui/icons-material/Person';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { useState } from "react";

const Navbar = ({ displaylogo }) => {
    const { logOut, user } = useUserAuth();
    const navigate = useNavigate();

    const [show, setShow] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const handleLogout = async () => {
        try {
            await logOut();
            navigate("/");
        } catch (error) {
            console.log(error.message);
        }
    };

    setTimeout(() => {
        setLoaded(true);
    }, 50);

    return (
        <div>
            {displaylogo && <div className="hidden w-screen h-14 mb-10 md:block "></div>}
            <nav className='hidden md:block fixed top-0 left-0  w-screen h-14 z-50'>

                <div className="flex justify-between h-12 items-center  text-lg font-semibold bg-primary">
                    <NavLink to="/" className='ml-10'>
                        {displaylogo && <img src={logo} alt="" className='w-20' />}
                    </NavLink>
                    {loaded && <div className='flex items-center z-50 w-2/5 justify-around mx-11'>
                        <NavLink to='/' className={({ isActive }) => (isActive ? "text-accent" : " ") + " hover:text-accent border-accent duration-200"}>Domov</NavLink>
                        <NavLink to='/matches' className={({ isActive }) => (isActive ? "text-accent" : " ") + " hover:text-accent border-accent duration-200"}>Zápasy</NavLink>
                        <NavLink to='/standings' className={({ isActive }) => (isActive ? "text-accent" : " ") + " hover:text-accent border-accent duration-200"}>Tabuľka</NavLink>
                        <NavLink to='/statistics' className={({ isActive }) => (isActive ? "text-accent" : " ") + " hover:text-accent border-accent duration-200"}>Štatistika</NavLink>
                        {user && <NavLink to='/admin' className={({ isActive }) => (isActive ? "text-accent" : " ") + " hover:text-accent border-b-2 border-accent duration-200"}> ADMIN </NavLink>}

                        {!user && <NavLink to='/login' className="hover:text-accent border-accent duration-200"> <PersonIcon /> </NavLink>}
                        {user && <div className="text-primary py-1 px-3 rounded-lg bg-accent cursor-pointer  duration-200" onClick={handleLogout}> Logout </div>}

                    </div>}
                </div>
                <div className="h-1 w-screen bg-gradient-to-l  from-[#f0da00]   via-transparent"></div>
            </nav >

            {!show && <nav className='block md:hidden fixed top-0 right-0  w-screen h-14 z-50'>
                <div className="float-right m-3 p-3 rounded-full bg-primary text-accent" onClick={e => setShow(true)}>
                    <TableRowsIcon fontSize="large" />
                </div>

            </nav >}
            {show &&
                <div className='block md:hidden fixed -top-1 right-0  w-[70vw] h-screen z-50 bg-primary'>
                    <div className="bold text-3xl float-right m-5 text-accent" onClick={e => setShow(false)}>X</div>
                    <div className="flex flex-col">
                        <div className="py-20 pr-5 text-2xl flex flex-col items-end justify-around h-[60vh]">
                            <NavLink to='/' className={({ isActive }) => (isActive ? "text-accent" : " ") + "  border-accent duration-200"}>Domov</NavLink>
                            <NavLink to='/matches' className={({ isActive }) => (isActive ? "text-accent" : " ") + "  border-accent duration-200"}>Zápasy</NavLink>
                            <NavLink to='/standings' className={({ isActive }) => (isActive ? "text-accent" : " ") + "  border-accent duration-200"}>Tabuľka</NavLink>
                            <NavLink to='/statistics' className={({ isActive }) => (isActive ? "text-accent" : " ") + " hborder-accent duration-200"}>Štatistika</NavLink>
                        </div>
                    </div>


                </div>

            }
        </div >

    );
}

export default Navbar;