import { createContext, useContext, useEffect, useState } from "react";
import { db } from '../firebase/config';
import { collection, query, getDocs, orderBy } from "firebase/firestore";

const dataContext = createContext();

export function DataContextProvider({ children }) {
    const [teams, setTeams] = useState([]);
    const [rounds, setRounds] = useState([])
    const [matches, setMatches] = useState([])
    const [players, setPlayers] = useState([])
    const getTeams = async () => {
        console.log("getting Teams");
        const q = query(collection(db, "teams"), orderBy("category", "desc"))
        const data = await getDocs(q)
        setTeams(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

        return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    };

    const getRounds = async () => {
        console.log("getting Rounds");
        const q = query(collection(db, "rounds"), orderBy("date"))
        const data = await getDocs(q)
        setRounds(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

        return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    };

    const getMatches = async () => {
        console.log("getting matches");
        const q = query(collection(db, "matches"), orderBy("time"))
        const data = await getDocs(q)
        setMatches(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

        return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    };

    const getPlayers = async () => {

        console.log("getting players");
        const q = query(collection(db, "players"))
        const data = await getDocs(q)
        setPlayers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

        return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    };

    useEffect(() => {
        getTeams();
        getRounds();
        getMatches();
    }, [])

    return (
        <dataContext.Provider
            value={{ teams, setTeams, rounds, setRounds, matches, setMatches, getTeams, getRounds, getMatches, players, setPlayers, getPlayers }}
        >
            {children}
        </dataContext.Provider>
    );
}

export function useData() {
    return useContext(dataContext);
}