// // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore'


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBKb6kj65dMghcpyuqxmO6aiA8fJI16npE",
    authDomain: "liga3h.firebaseapp.com",
    projectId: "liga3h",
    storageBucket: "liga3h.appspot.com",
    messagingSenderId: "42566930076",
    appId: "1:42566930076:web:404b0586387711c985e759",
    measurementId: "G-YXYLBZTVRB"
};


// // Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
