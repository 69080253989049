import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, } from "react-router-dom";

import { UserAuthContextProvider } from "./context/UserAuthContext";
import { DataContextProvider } from './context/DataContext';


import './styles/output.css';

import Home from './pages/Home';
import Matches from './pages/Matches';
import Standings from './pages/Standings';
import Statistics from './pages/Statistics';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Admin from './pages/Admin';
import ProtectedRoute from './components/ProtectedRoute';
import EditMatch from './pages/EditMatch';






const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/matches",
    element: <Matches />,
  },
  {
    path: "/standings",
    element: <Standings />,
  },
  {
    path: "/statistics",
    element: <Statistics />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/admin",
    element: <ProtectedRoute> <Admin /> </ProtectedRoute>,
  },
  {
    path: "/admin/:id",
    element: <ProtectedRoute> <Admin /> </ProtectedRoute>,
  },
  {
    path: "/admin/match/:id",
    element: <ProtectedRoute> <EditMatch /> </ProtectedRoute>
  }
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserAuthContextProvider>
      <DataContextProvider>
        <RouterProvider router={router} />
      </DataContextProvider>
    </UserAuthContextProvider>
  </React.StrictMode>
);