import { useState, useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';

import { db } from '../firebase/config';
import { doc, deleteDoc, addDoc, updateDoc, collection, where, query, getDocs } from "firebase/firestore";

const EditTeam = (props) => {

    const [newName, setNewName] = useState(props.team.name)
    const [confirm, setConfirm] = useState(false)
    const [addPlayer, setAddPlayer] = useState(false)

    const [pNumber, setPNumber] = useState(0)
    const [pName, setPName] = useState("")
    const [pSurname, setPSurname] = useState("")
    const [pPosition, setPPostion] = useState("hráč")

    const [players, setPlayers] = useState([])

    const [waiting, setWaiting] = useState(false)

    const deleteTeam = async () => {

        props.team.players.forEach(async e => {
            await deleteDoc(doc(db, "players", e));
        })
        await deleteDoc(doc(db, "teams", props.team.id));
        window.location.reload(false);



    }



    const newPlayer = async () => {
        setWaiting(true)
        setAddPlayer(false)
        let player = { name: pName, surname: pSurname, position: pPosition, goals: 0, assists: 0, saves: 0, number: pNumber, teamID: props.team.id, team: props.team.name, id: "x", recieved: 0, category: props.team.category, punishments: 0 }
        console.log(player);
        let playerID = await addDoc(collection(db, "players"), player)
        props.team.players.push(playerID.id);
        let temp = players.map(e => e);
        player.id = playerID.id;
        temp.push(player)
        setPlayers(temp)
        await updateDoc(doc(db, "teams", props.team.id), { players: props.team.players })
        setPPostion("hráč")
        setPSurname("")
        setPName("")
        setPNumber(0)
        setWaiting(false)
    }

    useEffect(() => {
        const getPlayers = async () => {
            const q = query(collection(db, "players"), where("teamID", "==", props.team.id))
            const data = await getDocs(q)
            setPlayers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getPlayers();
    }, [props.team])

    const deletePlayer = async (i) => {
        //let player = { name: pName, surname: pSurname, position: pPosition, goals: 0, assists: 0, saves: 0, number: pNumber, teamID: props.team.id, team: props.team.name, id: "x" }
        if (players[i].goals + players[i].assists + players[i].saves + players[i].recieved === 0) {
            await deleteDoc(doc(db, "players", players[i].id));
            let temp = players.map(e => e);
            temp.splice(i, 1)
            setPlayers(temp)
            let temp2 = props.team;
            temp2.players.splice(props.team.players.indexOf(players[i]), 1)
            await updateDoc(doc(db, "teams", props.team.id), { players: temp2.players })
        } else {
            alert("Nemožno odstrániť hráča ktorý sa už zúčastnil zápasu")
        }


    }

    const editTeamName = async () => {
        await updateDoc(doc(db, "teams", props.team.id), {
            name: newName
        })
        window.location.reload(false);
    }

    const [editing, setEditing] = useState(false)
    const [selectedPlayers, setSelectedPlayer] = useState(9999)


    const [eNumber, setENumber] = useState(0)
    const [eName, setEName] = useState("")
    const [eSurname, setESurname] = useState("")
    const [ePosition, setEPostion] = useState("hráč")

    const editPlayer = (i) => {
        setEditing(true)
        setSelectedPlayer(i)
        setENumber(players[i].number)
        setEName(players[i].name)
        setESurname(players[i].surname)
        setEPostion(players[i].position)

    }

    const saveEditPlayer = async () => {
        await updateDoc(doc(db, "players", players[selectedPlayers].id), { name: eName, surname: eSurname, position: ePosition, number: eNumber })
        let temp = players

        temp[selectedPlayers].name = eName
        temp[selectedPlayers].surname = eSurname
        temp[selectedPlayers].number = eNumber
        temp[selectedPlayers].position = ePosition

        setPlayers([...temp])

        setSelectedPlayer(9999)
        setEditing(false)

    }

    return (
        <div className="fixed flex w-screen h-screen top-0 left-0 justify-center items-center text-primary font-bold text-xl">
            <div className="absolute w-screen h-screen top-0 left-0 bg-black opacity-60"></div>
            <div className="z-30 w-[70vw] h-[80vh] bg-gray-300 rounded-lg relative p-8">
                <div className="absolute top-5 right-5 cursor-pointer" onClick={e => props.setShowEdit(false)}>X</div>
                <div className='text-3xl mb-10'> {props.team.name}</div>
                <div className='flex w-full'>
                    <div className='w-1/5  flex flex-col justify-between h-full '>
                        <div className='flex justify-between  items-center'>
                            <label className='relative'>Názov</label>
                            <input type="text" className='bg-gray-300 border-b-4 border-primary w-[7.8rem]' value={newName} onChange={e => setNewName(e.target.value)} />
                        </div>
                        {newName !== props.team.name && <div className='px-3 text-white py-1 bg-primary rounded-lg cursor-pointer mx-auto mt-3' onClick={editTeamName}>Uložiť zmeny</div>}
                        {props.team.category === "young" && <label className='mt-2 '>Kategória: Mladší </label>}
                        {props.team.category === "old" && <label className='mt-2 '>Kategória: Starší </label>}
                        <div className='w-full text-center mt-5'>

                            <div className='text-white py-1 bg-primary rounded-lg cursor-pointer mx-auto mt-3 ' onClick={e => { setAddPlayer(true); setConfirm(false) }}>Pridať hráča</div>
                            <div className='text-white py-2 bg-red-800 rounded-lg cursor-pointer flex items-center justify-center mx-auto mt-3 ' onClick={e => { setAddPlayer(false); setConfirm(true) }}> <span className='whitespace-pre'>Odstrániť Tím</span> <DeleteIcon /></div>

                        </div>

                        {confirm &&
                            <div className='bg-gray-400 mt-2 items-center p-1'>
                                <p>Naozaj chceš odstrániť tím <span className='underline'>{props.team.name}</span> ?</p>
                                <div className='flex justify-around mt-4'>
                                    <div className='text-white px-3 py-1 bg-primary rounded-lg cursor-pointer' onClick={e => setConfirm(false)}>Zrušiť</div>
                                    <div className='text-white px-3 py-1 bg-red-800 rounded-lg cursor-pointer' onClick={e => deleteTeam()}>Odstrániť</div>
                                </div>
                            </div>
                        }

                        {addPlayer &&
                            <div>
                                <form className='mt-6 flex-col flex text-base'>
                                    <div className='w-full flex justify-between items-center mx-2'>
                                        <label className=''>Meno</label>
                                        <input type="text" className='bg-transparent border-b-4 text-right border-primary w-24 mx-2' value={pName} onChange={e => setPName(e.target.value)} />
                                    </div>
                                    <div className='w-full flex justify-between  items-center mx-2'>
                                        <label className=''>Priezvisko</label>
                                        <input type="text" className='bg-transparent border-b-4 text-right border-primary w-24 mx-2' value={pSurname} onChange={e => setPSurname(e.target.value)} />
                                    </div>
                                    <div className='w-full flex justify-between  items-center ml-2 mt-1'>
                                        <label className=''>Pozícia</label>
                                        <select value={pPosition} onChange={e => setPPostion(e.target.value)} className="bg-transparent border-none focus:ring-0 px-2 mx-2   ">
                                            <option value={"hráč"}>hráč</option>
                                            <option value={"brankár"}>brankár</option>
                                        </select>
                                    </div>
                                    <div className='w-full flex justify-between  items-center mx-2'>
                                        <label className=''>Číslo:</label>
                                        <input type="text" className='bg-transparent border-b-4 text-right pr-1 border-primary w-12 mr-2 ' value={pNumber} onChange={e => setPNumber(e.target.value)} />

                                    </div>



                                </form>
                                {!waiting && <div className='text-white px-3 py-1 bg-primary rounded-lg cursor-pointer mx-auto mt-2 w-min whitespace-pre ' onClick={e => { newPlayer() }}>Pridať</div>}
                                {waiting && <div className='text-gray-400 px-3 py-1 bg-primary rounded-lg mx-auto mt-2 w-min whitespace-pre cursor-not-allowed'>Pridať</div>}
                            </div>

                        }
                    </div>
                    <div className='w-2/3 flex flex-col mx-10 relative '>
                        <div className='table'>
                            <div className='table-header-group bg-primary text-white'>
                                <div className='table-row [&>*]:px-4 [&>*]:py-2 [&>*]:border-b-2 [&>*]:border-gray-200'>
                                    <div className='table-cell'>Číslo</div>
                                    <div className='table-cell'>Meno</div>
                                    <div className='table-cell'>Priezvisko</div>
                                    <div className='table-cell'>Pozícia</div>
                                    <div className='table-cell border-l-2 border-gray-200'>G</div>
                                    <div className='table-cell'>A</div>
                                    <div className='table-cell'>Zák.</div>
                                    <div className='table-cell border-l-2 border-gray-200'><DeleteIcon /></div>
                                    <div className='table-cell border-l-2 border-gray-200'><SettingsIcon /></div>
                                </div>
                            </div>
                            <div className='table-row-group overflow-hidden'>
                                {players.map((e, i) => (
                                    <>
                                        {<div key={i} className='table-row bg-gray-300 text-center hover:bg-gray-200'>
                                            {selectedPlayers !== i && <div className="table-cell">{e.number}</div>}
                                            {selectedPlayers !== i && <div className="table-cell">{e.name}</div>}
                                            {selectedPlayers !== i && <div className="table-cell">{e.surname}</div>}
                                            {selectedPlayers !== i && <div className="table-cell">{e.position}</div>}
                                            {selectedPlayers !== i && <div className="table-cell">{e.goals}</div>}
                                            {selectedPlayers !== i && <div className="table-cell">{e.assists}</div>}
                                            {selectedPlayers !== i && <div className="table-cell">{e.saves}</div>}
                                            {selectedPlayers !== i && <div className="table-cell underline cursor-pointer text-red-700" onClick={f => (deletePlayer(i))}>delete</div>}
                                            {selectedPlayers !== i && <div className="table-cell underline cursor-pointer" onClick={f => (editPlayer(i))}>edit</div>}

                                            {(editing && selectedPlayers === i) && <div className="table-cell"><input type="text" className='bg-transparent border-b-2 border-primary w-12 text-center ' value={eNumber} onChange={e => setENumber(e.target.value)} /></div>}
                                            {(editing && selectedPlayers === i) && <div className="table-cell"><input type="text" className='bg-transparent border-b-2 border-primary w-24 ' value={eName} onChange={e => setEName(e.target.value)} /></div>}
                                            {(editing && selectedPlayers === i) && <div className="table-cell"><input type="text" className='bg-transparent border-b-2 border-primary w-24 ' value={eSurname} onChange={e => setESurname(e.target.value)} /></div>}
                                            {(editing && selectedPlayers === i) && <div className="table-cell">
                                                <select value={ePosition} onChange={e => setEPostion(e.target.value)} className="bg-transparent border-none focus:ring-0 px-2 mx-2   ">
                                                    <option value={"hráč"}>hráč</option>
                                                    <option value={"brankár"}>brankár</option>
                                                </select>
                                            </div>}
                                            {(editing && selectedPlayers === i) && <div className="table-cell">{e.goals}</div>}
                                            {(editing && selectedPlayers === i) && <div className="table-cell">{e.assists}</div>}
                                            {(editing && selectedPlayers === i) && <div className="table-cell">{e.saves}</div>}
                                            {(editing && selectedPlayers === i) && <div className="table-cell underline cursor-pointer text-red-700" onClick={f => (deletePlayer(i))}>delete</div>}
                                            {(editing && selectedPlayers === i) && <div className="table-cell text-white bg-primary cursor-pointer" onClick={f => (saveEditPlayer())}>save</div>}
                                        </div>}
                                    </>
                                ))}
                            </div>

                        </div>


                    </div>

                </div>


            </div>

        </div>
    );
}

export default EditTeam;