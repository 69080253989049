import Navbar from "../components/Navbar";

import { useData } from "../context/DataContext";

import { useEffect, useState } from "react";

const Statistics = () => {
    const { teams, getPlayers, players } = useData();

    const [young, setYoung] = useState(true)

    useEffect(() => {
        if (players.length === 0) {
            getPlayers();
        }
        //eslint-disable-next-line
    }, [])

    // const team = { name: name, category: "old", won: 0, tie: 0, lost: 0, played: 0, points: 0, score: [0, 0], players: [] }
    //let player = { name: pName, surname: pSurname, position: pPosition, goals: 0, assists: 0, saves: 0, number: pNumber, teamID: props.team.id, team: props.team.name, id: "x", recieved: 0, category: props.team.category }
    return (
        <div>
            <Navbar displaylogo={true} />

            <div className="mt-20 flex cursor-pointer text-lg w-full mx-auto md:mt-10 justify-center">
                <div className={"px-4 py-2  border-r-0  border-accent font-bold  " + (young ? "border-b-4 text-accent" : "text-white bg-primary")} onClick={e => setYoung(true)}>Kategória Mladší</div>
                <div className={"px-4 py-2  border-l-0  border-accent font-bold " + (!young ? "border-b-4 text-accent" : "text-white bg-primary")} onClick={e => setYoung(false)}>Kategória Starší</div>
            </div>
            <div className="flex justify-around mt-5 mb-20">

                <div>
                    <div className="table rounded-lg bg-gray-200 mx-5  text-primary">
                        <div className="table-header-group sticky rounded-t-xl">
                            <div className="table-row p-4 font-bold text-base md:text-lg text-center rounded-t-lg">
                                <div className="hidden md:table-cell pl-1 md:pl-2">Por.</div>
                                <div className="table-cell  md:px-2 md:border-r-2 md:border-primary">Hráč</div>
                                <div className="hidden md:table-cell px-1 md:px-2 py-2">Góly</div>
                                <div className="hidden md:table-cell px-1 md:px-2 py-2">Asistencie</div>
                                <div className="table-cell md:hidden px-1 md:px-2 py-2">G</div>
                                <div className="table-cell md:hidden px-1 md:px-2 py-2">A</div>
                                <div className="hidden md:table-cell px-1 md:px-2 py-2">Tresty</div>
                                <div className="table-cell md:hidden px-1 md:px-2 py-2">Trest</div>
                                <div className="table-cell px-1 md:px-1 py-2">Tím</div>
                            </div>
                        </div>
                        <div className="table-row-group rounded-lg ">
                            {players.filter(x => { return (((x.category === "young" && young) || (x.category === "old" && !young)) && (x.goals > 0 || x.assists > 0 || x.punishments > 0)) }).sort((a, b) => b.goals - a.goals || b.assists - a.assists || a.punishments - b.punishments).map((e, i) => (
                                <div key={i} className="table-row p-4 font-bold text-sm md:text-lg odd:bg-gray-300">
                                    <div className={"hidden md:table-cell pl-1 md:pl-4 text-center"}>{i + 1}.</div>
                                    <div className="table-cell px-1 md:px-2 text-center md:border-r-2 md:border-primary"><span className="md:hidden">{i + 1}. </span>{e.name} {e.surname} </div>
                                    <div className="table-cell px-1 md:px-2 py-3 text-center">{e.goals}</div>
                                    <div className="table-cell px-1 md:px-2 py-3 text-center">{e.assists}</div>
                                    <div className="hidden md:table-cell px-2 py-3 text-center">{e.punishments} min</div>
                                    <div className="table-cell md:hidden px-2 py-3 text-center">{e.punishments} m.</div>
                                    <div className="table-cell pr-2 md:px-2 md:pr-4 py-3 text-center whitespace-nowrap">{teams.find(x => x.id === e.teamID).name} </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>

            </div>
            {false && <div className="flex justify-around mt-20">

                <div className="mb-20">
                    <div className="table rounded-lg bg-gray-200  text-primary">
                        <div className="table-header-group  rounded-t-lg">
                            <div className="table-row p-4 font-bold text-xl text-center rounded-t-lg">
                                <div className="table-cell pl-2">Por.</div>
                                <div className="table-cell t px-4 pr-2 border-r-2 border-primary">Brankár</div>
                                <div className="table-cell px-2 py-2">Zákroky</div>
                                <div className="table-cell px-2 py-2">Neúspešné</div>
                                <div className="table-cell px-2 py-2">%</div>
                                <div className="table-cell px-2 py-2">Tresty</div>
                                <div className="table-cell px-2 py-2">Tím</div>
                            </div>
                        </div>
                        <div className="table-row-group rounded-lg">
                            {players.filter(x => { return (x.category === "young" && (x.saves > 0 || x.recieved > 0)) }).sort((a, b) => (Math.floor((100 - (b.recieved / b.saves * 100)) * 100) / 100) - (Math.floor((100 - (a.recieved / a.saves * 100)) * 100) / 100)).map((e, i) => (
                                <div className="table-row p-4 font-bold text-xl odd:bg-gray-300">
                                    <div className={"table-cell pl-4 text-center"}>{i + 1}.</div>
                                    <div className="table-cell px-6 pr-4 border-r-2 border-primary">{e.name} {e.surname} </div>
                                    <div className="table-cell px-6 py-4 text-center">{e.saves} </div>
                                    <div className="table-cell px-6 py-4 text-center">{e.recieved} </div>
                                    <div className="table-cell px-6 py-4 text-center">
                                        {console.log((Math.floor((100 - (e.recieved / e.saves * 100)) * 100) / 100))}
                                        {(Math.floor((100 - (e.recieved / e.saves * 100)) * 100) / 100) < 0 && <span>0</span>}
                                        {(Math.floor((100 - (e.recieved / e.saves * 100)) * 100) / 100) > 0 && <span>{Math.floor((100 - (e.recieved / e.saves * 100)) * 100) / 100}</span>}
                                    </div>
                                    <div className="table-cell px-6 py-4 text-center">{e.punishments} min </div>
                                    <div className="table-cell px-6 py-4 text-center">{teams.find(x => x.id === e.teamID).name} </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
                <div className="mb-20">
                    <div className="table rounded-lg bg-gray-200  text-primary">
                        <div className="table-header-group  rounded-t-lg">
                            <div className="table-row p-4 font-bold text-xl text-center rounded-t-lg">
                                <div className="table-cell pl-2">Por.</div>
                                <div className="table-cell t px-4 pr-2 border-r-2 border-primary">Brankár</div>
                                <div className="table-cell px-2 py-2">Zákroky</div>
                                <div className="table-cell px-2 py-2">Neúspešné</div>
                                <div className="table-cell px-2 py-2">%</div>
                                <div className="table-cell px-2 py-2">Tresty</div>
                                <div className="table-cell px-2 py-2">Tím</div>
                            </div>
                        </div>
                        <div className="table-row-group rounded-lg">
                            {players.filter(x => { return (x.category === "old" && (x.saves > 0 || x.recieved > 0)) }).sort((a, b) => (Math.floor((100 - (b.recieved / b.saves * 100)) * 100) / 100) - (Math.floor((100 - (a.recieved / a.saves * 100)) * 100) / 100)).map((e, i) => (
                                <div className="table-row p-4 font-bold text-xl odd:bg-gray-300">
                                    <div className={"table-cell pl-4 text-center"}>{i + 1}.</div>
                                    <div className="table-cell px-6 pr-4 border-r-2 border-primary">{e.name} {e.surname} </div>
                                    <div className="table-cell px-6 py-4 text-center">{e.saves} </div>
                                    <div className="table-cell px-6 py-4 text-center">{e.recieved} </div>
                                    <div className="table-cell px-6 py-4 text-center">
                                        {(Math.floor((100 - (e.recieved / e.saves * 100)) * 100) / 100) < 0 && <span>0</span>}
                                        {(Math.floor((100 - (e.recieved / e.saves * 100)) * 100) / 100) > 0 && <span>{Math.floor((100 - (e.recieved / e.saves * 100)) * 100) / 100}</span>}
                                    </div>
                                    <div className="table-cell px-6 py-4 text-center">{e.punishments} min </div>
                                    <div className="table-cell px-6 py-4 text-center">{teams.find(x => x.id === e.teamID).name} </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>}

        </div>
    );
}

export default Statistics;

