import Navbar from "../components/Navbar";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import EditTeam from "../components/EditTeam";

import { db } from '../firebase/config';
import { collection, addDoc, deleteDoc, doc, updateDoc, query, getDocs } from "firebase/firestore";
import { format } from "date-fns/esm";
import { useData } from "../context/DataContext";


const Admin = () => {

    const { teams, setTeams, rounds, setRounds, matches, setMatches } = useData();
    const { id } = useParams();
    const [name, setName] = useState("")
    const [young, setYoung] = useState(false)
    const [old, setOld] = useState(false)
    const [selectedTeam, setSelectedTeam] = useState({})
    const [showEdit, setShowEdit] = useState(false)
    const [addRoundPopup, setAddRoundPopup] = useState(false)
    const [addMatchPopup, setAddMatchPopup] = useState(false)
    const [selectedRound, setSelectedRound] = useState({ matches: [] })
    const [cat, setCat] = useState("young")
    const [roundDate, setRoundDate] = useState("")
    const [deleteRoundConfirm, setDeleteRoundConfirm] = useState(false)
    const [displayMatches, setDisplayMatches] = useState([])
    const [players, setPlayers] = useState([])

    const addTeam = (e) => {
        e.preventDefault()
        if (name === "") {
            alert("Zadaj názov tímu!")
            return
        }
        if (!young && !old) {
            alert("Zvoľ aspoň jednu kategóriu!")
            return
        }

        const addTeam = async () => {
            let temp = []
            if (old) {
                let obj = { name: name, category: "old", won: 0, tie: 0, lost: 0, played: 0, points: 0, score: [0, 0], players: [] }

                let res = await addDoc(collection(db, "teams"), obj)
                obj = { ...obj, id: res.id }
                temp.push(obj)
            }
            if (young) {
                let obj = { name: name, category: "young", won: 0, tie: 0, lost: 0, played: 0, points: 0, score: [0, 0], players: [] }

                let res = await addDoc(collection(db, "teams"), obj)
                obj = { ...obj, id: res.id }
                temp.push(obj)
            }

            setTeams([...teams, ...temp])
        };

        addTeam()

        setName("")
        setOld(false);
        setYoung(false);

    }

    useEffect(() => {
        if (rounds.length > 0) {
            if (id) {
                setSelectedRound(rounds.find(e => e.id === id))
            } else {
                let dis = 999999999999999999
                rounds.forEach(e => {
                    let d = new Date(e.date)
                    let t = new Date()

                    if (Math.abs(d.getTime() - t.getTime()) < dis && d.getTime() - t.getTime() > -(24 * 60 * 60 * 1000)) {
                        dis = Math.abs(d.getTime() - t.getTime())
                        setSelectedRound(e)
                    }



                })
                // only for fiull rewrite
                //getPlayers()
            }
        }

        //eslint-disable-next-line
    }, [rounds, id])


    const addRound = async () => {
        const obj = { date: roundDate, matches: [] }
        setRoundDate(new Date())
        let res = await addDoc(collection(db, "rounds"), obj)
        let temp = rounds.map(e => e)
        temp.push({ ...obj, id: res.id })
        setAddRoundPopup(false)
        temp.sort((a, b) => new Date(a.date) - new Date(b.date))
        setRounds(temp)
        setSelectedRound(temp[temp.length - 1])
    }

    const [matchTime, setMatchTime] = useState(new Date())
    const [selectedTeam1, setSelectedTeam1] = useState(" ")
    const [selectedTeam2, setSelectedTeam2] = useState(" ")



    const addMatch = async () => {

        if (selectedTeam1 === " " || selectedTeam2 === " ") {
            alert("Vyplň všetky polia!")
        }
        let match = { time: matchTime, round: selectedRound.id, team: [selectedTeam1, selectedTeam2], teamName: [teams.find(x => x.id === selectedTeam1).name, teams.find(x => x.id === selectedTeam2).name], score: [0, 0], state: "planned", category: cat, goals: [], saves: [], punishments: [], kontumacia: false }
        let res = await addDoc(collection(db, "matches"), match)
        await updateDoc(doc(db, "rounds", selectedRound.id), { matches: [...selectedRound.matches, res.id] })
        setMatches([...matches, { ...match, id: res.id }])
        let temp = rounds
        temp.find(x => x.id === selectedRound.id).matches = [...temp.find(x => x.id === selectedRound.id).matches, res.id]
        setRounds(temp);

        setMatchTime(new Date())
        setSelectedTeam1(" ")
        setSelectedTeam2(" ")

        setAddMatchPopup(false)
    }

    const deleteRound = async () => {

        rounds.find(x => x.id === selectedRound.id).matches.forEach(async e => {
            await deleteDoc(doc(db, "matches", e))
        })
        let temp = rounds;
        temp.splice(rounds.indexOf(selectedRound), 1)
        setRounds(temp)
        await deleteDoc(doc(db, "rounds", selectedRound.id))
        setDeleteRoundConfirm(false)
    }

    useEffect(() => {
        let temp = [];

        selectedRound.matches.forEach((e) => {
            let match = matches.find(x => x.id === e)
            temp.push(match)
        })
        temp.forEach(e => console.log(e.time))

        temp.sort((a, b) => new Date("1970-01-01T" + a.time) - new Date("1970-01-01T" + b.time))

        setDisplayMatches(temp)

    }, [selectedRound, matches])





    //FULL REWRITE DATA
    // //#region

    // useEffect(() => {
    //     const getPlayers = async () => {
    //         const q = query(collection(db, "players"))
    //         const data = await getDocs(q)
    //         setPlayers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    //     };

    //     getPlayers();
    // }, [])


    // const clearData = async () => {
    //     console.log("clearing");

    //     players.forEach(async p => {
    //         await updateDoc(doc(db, "players", p.id), { goals: 0, assists: 0, saves: 0, punishments: 0 })
    //     })

    //     teams.forEach(async t => {
    //         await updateDoc(doc(db, "teams", t.id), { won: 0, tie: 0, lost: 0, played: 0, points: 0, score: [0, 0] })
    //     })

    // }

    // const fullRewrite = async () => {
    //     // let match = { time, round:, team: [selectedTeam1, selectedTeam2], teamName: [teams.find(x => x.id === selectedTeam1).name, teams.find(x => x.id === selectedTeam2).name], score: [0, 0], state: "planned", category: cat, goals: [], saves: [], punishments: [] }
    //     console.log("-------------------------------------");
    //     //let player = { name: pName, surname: pSurname, position: pPosition, goals: 0, assists: 0, saves: 0, number: pNumber, teamID: props.team.id, team: props.team.name, id: "x" }

    //     let localPlayers = players
    //     let localTeams = teams
    //     let countG = []
    //     let countA = []
    //     let countR = []

    //     let countP = []
    //     let countS = []

    //     //let obj = { name: name, category: "old", won: 0, tie: 0, lost: 0, played: 0, points: 0, score: [0, 0], players: [] }
    //     matches.forEach(async m => {
    //         console.log(m.id);
    //         m.goals.forEach(async e => {

    //             if (countG.find(x => x.player === e.shooter)) {
    //                 countG.find(x => x.player === e.shooter).goals++
    //             } else {
    //                 countG.push({ player: e.shooter, goals: 1 })
    //             }

    //             if (e.assistant) {
    //                 if (countA.find(x => x.player === e.assistant)) {
    //                     countA.find(x => x.player === e.assistant).assists++
    //                 } else {
    //                     countA.push({ player: e.assistant, assists: 1 })
    //                 }
    //             }

    //             if (countR.find(x => x.player === e.keeper)) {
    //                 countR.find(x => x.player === e.keeper).recieved++
    //             } else {
    //                 countR.push({ player: e.keeper, recieved: 1 })
    //             }
    //         });

    //         m.punishments.forEach(async p => {
    //             if (countP.find(x => x.player === p.player)) {
    //                 countP.find(x => x.player === p.player).punishments += p.time
    //             } else {
    //                 countP.push({ player: p.player, punishments: p.time })
    //             }
    //         });

    //         m.saves.forEach(async s => {
    //             if (countS.find(x => x.player === s.keeper)) {
    //                 countS.find(x => x.player === s.keeper).saves += s.saves
    //             } else {
    //                 countS.push({ player: s.keeper, saves: s.saves })
    //             }
    //         })

    //         if (m.score[0] > m.score[1]) {
    //             localTeams.find(x => x.id === m.team[0]).played += 1
    //             localTeams.find(x => x.id === m.team[0]).won += 1
    //             localTeams.find(x => x.id === m.team[0]).points += 3
    //             localTeams.find(x => x.id === m.team[0]).score[0] += m.score[0]
    //             localTeams.find(x => x.id === m.team[0]).score[1] += m.score[1]

    //             localTeams.find(x => x.id === m.team[1]).played += 1
    //             localTeams.find(x => x.id === m.team[1]).lost += 1
    //             localTeams.find(x => x.id === m.team[1]).score[0] += m.score[1]
    //             localTeams.find(x => x.id === m.team[1]).score[1] += m.score[0]
    //         } else if (m.score[0] < m.score[1]) {
    //             localTeams.find(x => x.id === m.team[1]).played += 1
    //             localTeams.find(x => x.id === m.team[1]).won += 1
    //             localTeams.find(x => x.id === m.team[1]).points += 3
    //             localTeams.find(x => x.id === m.team[1]).score[0] += m.score[1]
    //             localTeams.find(x => x.id === m.team[1]).score[1] += m.score[0]

    //             localTeams.find(x => x.id === m.team[0]).played += 1
    //             localTeams.find(x => x.id === m.team[0]).lost += 1
    //             localTeams.find(x => x.id === m.team[0]).score[0] += m.score[0]
    //             localTeams.find(x => x.id === m.team[0]).score[1] += m.score[1]

    //         } else {
    //             localTeams.find(x => x.id === m.team[0]).played += 1
    //             localTeams.find(x => x.id === m.team[0]).tie += 1
    //             localTeams.find(x => x.id === m.team[0]).points += 1
    //             localTeams.find(x => x.id === m.team[0]).score[0] += m.score[0]
    //             localTeams.find(x => x.id === m.team[0]).score[1] += m.score[1]

    //             localTeams.find(x => x.id === m.team[1]).played += 1
    //             localTeams.find(x => x.id === m.team[1]).tie += 1
    //             localTeams.find(x => x.id === m.team[1]).points += 1
    //             localTeams.find(x => x.id === m.team[1]).score[0] += m.score[1]
    //             localTeams.find(x => x.id === m.team[1]).score[1] += m.score[0]
    //         }
    //     })

    //     console.log(localTeams);

    //     countG.forEach(async g => {
    //         await updateDoc(doc(db, "players", g.player), { goals: localPlayers.find(x => x.id === g.player).goals + g.goals })
    //         localPlayers.find(x => x.id === g.player).goals += g.goals
    //     })
    //     countA.forEach(async a => {
    //         await updateDoc(doc(db, "players", a.player), { assists: localPlayers.find(x => x.id === a.player).assists + a.assists })
    //         localPlayers.find(x => x.id === a.player).assists += a.assists
    //     })
    //     countR.forEach(async r => {
    //         await updateDoc(doc(db, "players", r.player), { recieved: localPlayers.find(x => x.id === r.player).recieved + r.recieved })
    //         localPlayers.find(x => x.id === r.player).recieved += r.recieved
    //     })
    //     countP.forEach(async p => {
    //         await updateDoc(doc(db, "players", p.player), { punishments: localPlayers.find(x => x.id === p.player).punishments + p.punishments })
    //         localPlayers.find(x => x.id === p.player).punishments += p.punishments
    //     })
    //     countS.forEach(async s => {
    //         await updateDoc(doc(db, "players", s.player), { saves: localPlayers.find(x => x.id === s.player).saves + s.saves })
    //         localPlayers.find(x => x.id === s.player).saves += s.saves
    //     })

    //     localTeams.forEach(async t => {
    //         await updateDoc(doc(db, "teams", t.id), { played: t.played, won: t.won, lost: t.lost, tie: t.tie, points: t.points, score: [...t.score] })
    //     })
    // }

    // //#endregion

    return (
        <div className="overflow-x-hidden">
            <Navbar displaylogo={true} />

            <div className="w-[80vw] bg-gray-300 mx-auto rounded-xl">
                <div className="p-10 pb-2 text-primary">
                    <h1 className="text-2xl font-bold mb-2">Tímy</h1>
                    <hr />
                    <div className="py-4 flex">
                        <div className="table border-collapse">
                            <div className="table-header-group bg-primary text-white ">
                                <div className="table-row">
                                    <div className="table-cell px-3 py-1 border border-gray-300">Názov</div>
                                    <div className="table-cell px-3 py-1 border border-gray-300">Kategória</div>
                                    <div className="table-cell px-4 py-2 border border-gray-300">Zápasy</div>
                                    <div className="table-cell px-4 py-2 border border-gray-300">Výhry</div>
                                    <div className="table-cell px-4 py-2 border border-gray-300">Remízy</div>
                                    <div className="table-cell px-4 py-2 border border-gray-300">Prehry</div>
                                    <div className="table-cell px-4 py-2 border border-gray-300">Skóre</div>
                                    <div className="table-cell px-4 py-2 border border-gray-300">+/-</div>
                                    <div className="table-cell px-4 py-2 border border-gray-300">Body</div>
                                    <div className="table-cell px-4 py-2 border border-gray-300">Hráči</div>
                                    <div className="table-cell px-4 py-2 border border-gray-300">Upraviť</div>
                                </div>
                            </div>
                            <div className="table-row-group">
                                {teams.map((e, i) => (
                                    <div key={i} className={" table-row text-lg py-1 px-4  bg-gray-100 "}>
                                        <div className="table-cell px-3 py-1 border border-primary">{e.name}</div>
                                        <div className={"table-cell px-3 py-1 border border-primary " + (e.category === "young" ? "bg-gray-300" : " ")} >{e.category === "old" && <span>starší</span>}{e.category === "young" && <span>mladší</span>}</div>
                                        <div className="table-cell px-4 py-2 border border-primary">{e.played}</div>
                                        <div className="table-cell px-4 py-2 border border-primary">{e.won}</div>
                                        <div className="table-cell px-4 py-2 border border-primary">{e.tie}</div>
                                        <div className="table-cell px-4 py-2 border border-primary">{e.lost}</div>
                                        <div className="table-cell px-4 py-2 border border-primary">{e.score[0]}:{e.score[1]}</div>
                                        <div className="table-cell px-4 py-2 border border-primary">{e.score[0] - e.score[1]}</div>
                                        <div className="table-cell px-4 py-2 border border-primary">{e.points}</div>
                                        <div className="table-cell px-4 py-2 border border-primary">{e.players.length}</div>
                                        <div className="table-cell px-4 py-2 underline cursor-pointer border border-primary" onClick={f => { setSelectedTeam(e); setShowEdit(true) }}>Upraviť</div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>

                    <hr />
                    <form className="flex justify-around my-5" onSubmit={addTeam}>
                        <div>
                            <label>Názov tímu*&nbsp;</label>
                            <input type="text" value={name} onChange={e => setName(e.target.value)} />
                        </div>

                        <div>
                            <label>Starší&nbsp;</label>
                            <input type="checkbox" checked={old} onChange={e => setOld(!old)} />
                        </div>
                        <div>
                            <label>Mladší&nbsp;</label>
                            <input type="checkbox" checked={young} onChange={e => setYoung(!young)} />
                        </div>

                        <button className="bg-primary py-1 px-4 text-white">Pridať tím</button>
                    </form>
                    <hr />
                    <h1 className="text-2xl font-bold mb-2 text-primary mt-2">Zápasy</h1>
                    <div className={"flex mb-5 justify-around " + (rounds.length === 0 ? " items-center" : "")}>
                        <div className="bg-primary text-white ">
                            <div className={" p-2 text-center cursor-pointer " + (rounds.length === 0 ? " " : " border-b-4 border-white")} onClick={e => setAddRoundPopup(true)}>Pridať kolo</div>
                            {rounds.map((e, i) => (
                                <div key={i} onClick={f => setSelectedRound(e)} className={" flex hover:bg-[#2c3e7d] hover:text-white p-2 border-b border-white cursor-pointer " + ((selectedRound === e) ? "bg-white text-primary" : "text-white")}>
                                    <div className="pr-4">{i + 1}. </div>
                                    <div>{format(new Date(e.date), "d.MM.yyyy")}</div>
                                </div>
                            ))}
                        </div>
                        {rounds.length > 0 &&
                            <div className="bg-white text-primary flex-1 ">
                                {selectedRound.matches.length === 0 &&
                                    <div>Neexistuju žiadne zápasy v tomto kole</div>
                                }
                                <div className="table mx-auto m-8">
                                    {displayMatches.map((match, i) => {
                                        return (
                                            <div key={i} className="table-row text-center [&>*]:p-4 odd:bg-slate-300">
                                                <div className="table-cell">{match.time}</div>
                                                <div className="table-cell border-l-4">{match.teamName[0]}</div>
                                                <div className="table-cell">{match.score[0]}:{match.score[1]}</div>
                                                <div className="table-cell  border-r-4">{match.teamName[1]}</div>
                                                {match.category === "young" && <div className="table-cell">mladší</div>}
                                                {match.category === "old" && <div className="table-cell">starší</div>}
                                                <div className="table-cell">{match.state}</div>
                                                <Link to={`/admin/match/${match.id}`} className="table-cell underline cursor-pointer">Spravovať</Link>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className="flex justify-around m-5">
                                    <div className='text-white px-3 py-2 bg-primary rounded-lg cursor-pointer' onClick={e => setAddMatchPopup(true)}>Pridať zápas</div>
                                    <div className='text-white px-3 py-2 bg-red-800 rounded-lg cursor-pointer' onClick={f => setDeleteRoundConfirm(true)}>Odstrániť kolo</div>
                                </div>


                            </div>
                        }
                        {rounds.length === 0 &&
                            <div>
                                Neexistuje žiadne kolo
                            </div>
                        }
                    </div>
                    {addRoundPopup &&
                        <div className="fixed flex w-screen h-screen top-0 left-0 justify-center items-center text-primary font-bold text-xl">
                            <div className="absolute w-screen h-screen top-0 left-0 bg-black opacity-60"></div>
                            <div className="z-30  bg-gray-300 rounded-lg relative p-8">
                                <h1 className="text-2xl font-bold mb-2 text-primary mt-2">Pridať kolo</h1>
                                <input type="date" value={roundDate} onChange={e => setRoundDate(e.target.value)} />
                                <div className="flex">
                                    <div className='text-white px-3 py-1 bg-red-800 rounded-lg cursor-pointer w-min mx-auto mt-4' onClick={e => setAddRoundPopup(false)}>Zrušiť</div>
                                    <div className='text-white px-3 py-1 bg-primary rounded-lg cursor-pointer w-min mx-auto mt-4' onClick={e => addRound()}>Pridať</div>
                                </div>

                            </div>
                        </div>
                    }
                    {deleteRoundConfirm &&
                        <div className="fixed flex w-screen h-screen top-0 left-0 justify-center items-center text-primary font-bold text-xl">
                            <div className="absolute w-screen h-screen top-0 left-0 bg-black opacity-60"></div>
                            <div className="z-30  bg-gray-300 rounded-lg relative p-8">
                                <h1 className="text-2xl font-bold mb-2 text-primary mt-2">Naozaj odstrániť kolo z {<span>{format(new Date(selectedRound.date), "d.MM.yyyy")}</span>} s {selectedRound.matches.length} zápasmi?</h1>
                                <div className="flex">
                                    <div className='text-white px-3 py-1 bg-primary rounded-lg cursor-pointer w-min mx-auto mt-4' onClick={e => setDeleteRoundConfirm(false)}>Zrušiť</div>
                                    <div className='text-white px-3 py-1 bg-red-800 rounded-lg cursor-pointer w-min mx-auto mt-4' onClick={e => deleteRound()}>Odstrániť</div>
                                </div>

                            </div>
                        </div>
                    }
                    {addMatchPopup &&
                        <div className="fixed flex w-screen h-screen top-0 left-0 justify-center items-center text-primary font-bold text-xl">
                            <div className="absolute w-screen h-screen top-0 left-0 bg-black opacity-60"></div>
                            <div className="z-30  bg-gray-300 rounded-lg relative p-8">
                                <h1 className="text-2xl font-bold mb-2 text-primary mt-2">Pridať zápas</h1>
                                <div className="py-2">
                                    <label className="pr-2">Čas</label>
                                    <input type="time" value={matchTime} onChange={e => setMatchTime(e.target.value)} />
                                </div>
                                <div className="py-2">
                                    <label>Kategória</label>
                                    <select value={cat} onChange={e => setCat(e.target.value)} className=" border-none focus:ring-0 px-4 mx-12   ">
                                        <option value={"old"}>Starší</option>
                                        <option value={"young"}>Mladší</option>
                                    </select>
                                </div>
                                <div className="py-2">
                                    <label>Tím 1</label>
                                    <select value={selectedTeam1} onChange={e => setSelectedTeam1(e.target.value)} className=" border-none focus:ring-0 px-4 mx-12   ">
                                        <option value=""></option>
                                        {teams.filter(e => e.category === cat).map(e => (
                                            <option key={e.id} value={e.id}>{e.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="py-2">
                                    <label>Tím 2</label>
                                    <select value={selectedTeam2} onChange={e => setSelectedTeam2(e.target.value)} className=" border-none focus:ring-0 px-4 mx-12   ">
                                        <option value=""></option>
                                        {teams.filter(e => e.category === cat).map(e => (
                                            <option value={e.id} key={e.id}>{e.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex">
                                    <div className='text-white px-3 py-1 bg-red-800 rounded-lg cursor-pointer w-min mx-auto mt-4' onClick={e => setAddMatchPopup(false)}>Zrušiť</div>
                                    <div className='text-white px-3 py-1 bg-primary rounded-lg cursor-pointer w-min mx-auto mt-4' onClick={e => addMatch()}>Pridať</div>
                                </div>

                            </div>
                        </div>
                    }
                    <hr />
                </div>


            </div>

            {showEdit && <EditTeam team={selectedTeam} setShowEdit={setShowEdit} setTeams={setTeams} />}


        </div>
    );
}

export default Admin;