import { Link, useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useData } from "../context/DataContext";
import SettingsIcon from '@mui/icons-material/Settings';
import { format } from "date-fns"
import { sk } from "date-fns/locale";
import { useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { db } from '../firebase/config';
import { doc, updateDoc, increment, arrayUnion, arrayRemove } from "firebase/firestore";
import { useEffect } from "react";

const EditMatch = () => {
    const { matches, rounds, teams, getPlayers, players, setPlayers } = useData();
    const { id } = useParams();
    const [match, setMatch] = useState()
    const [matchTeams, setMatchTeams] = useState()
    const [shooter, setShooter] = useState("Strelec")
    const [assistant, setAssistant] = useState("")
    const [endMatchPopup, setEndMatchPopup] = useState(false)
    const [keeper, setKeeper] = useState(["Brankár", "Brankár"])
    const [punishments, setPunishments] = useState(["Hráč", "Hráč"])
    const [mins, setMins] = useState(1)
    const [matchTime, setMatchTime] = useState(new Date())
    const [kontumPopup, setKontumPopup] = useState(false);
    const [selectedWinner, setSelectedWinner] = useState(0);
    const [pending, setPending] = useState(false);
    useEffect(() => {
        if (match) {
            let temp = [{}, {}]
            temp[0] = teams.find(x => x.id === match.team[0]);
            temp[1] = teams.find(x => x.id === match.team[1]);
            setMatchTeams(temp)
            setMatchTime()
        }
    }, [match, teams])


    useEffect(() => {
        if (players.length === 0) {
            getPlayers();
        }
        //eslint-disable-next-line
    }, [])



    useEffect(() => {
        setMatch(matches.find(x => x.id === id))
    }, [matches, id])

    const getRound = () => {
        let temp;

        rounds.forEach(e => {
            if (e.matches.includes(id)) {
                temp = e
            }
        });

        return temp
    }
    const round = getRound();

    const addGoal = async (team) => {
        if (shooter !== "Strelec") {
            if (keeper[1 - team] !== "Brankár") {
                let tempAssist = assistant
                if (assistant === "Asistencia") {
                    setAssistant(undefined)
                    tempAssist = undefined
                }



                await updateDoc(doc(db, "matches", id), { score: [match.score[0] + 1 - team, match.score[1] + team], goals: arrayUnion({ team, shooter, assistant: tempAssist, keeper: keeper[1 - team] }) })


                let temp = match

                if (temp === undefined) {
                    alert("Unexpected error occured! Please reload the page");
                    return
                }

                temp.score = [match.score[0] + 1 - team, match.score[1] + team]
                temp.goals = [...match.goals, { team, shooter, assistant: tempAssist, keeper: keeper[1 - team] }]

                setMatch({ ...temp })
                setShooter("")
                setAssistant("")

            } else {
                alert("Vyber brankára v týme nepriateľa!")
            }

        } else {
            alert("Vyber strelca gólu!")
        }
    }

    const deleteGoal = async (e) => {


        let temp = match
        if (temp === undefined) {
            alert("Unexpected error occured! Please reload the page");
            return
        }
        temp.score = [match.score[0] - 1 + e.team, match.score[1] - e.team]
        temp.goals.splice(match.goals.indexOf(e), 1)
        await updateDoc(doc(db, "matches", id), { score: temp.score, goals: arrayRemove(e) })

        setMatch({ ...temp })
    }

    const addPunishment = async (team) => {
        if (punishments[team] !== "Hráč") {

            await updateDoc(doc(db, "matches", id), { punishments: arrayUnion({ team, player: punishments[team], time: mins }) })
            let temp = match
            if (temp === undefined) {
                alert("Unexpected error occured! Please reload the page");
                return
            }
            temp.punishments = [...match.punishments, { team, player: punishments[team], time: mins }]
            setMatch({ ...temp })
            setPunishments(["Hráč", "Hráč"])
        } else {
            alert("Vyber trestaného hráča!")
        }
    }

    const deletePunishment = async (e) => {
        let temp = match
        if (temp === undefined) {
            alert("Unexpected error occured! Please reload the page");
            return
        }
        temp.punishments.splice(match.punishments.indexOf(e), 1)
        await updateDoc(doc(db, "matches", id), { punishments: arrayRemove(e) })

        setMatch({ ...temp })
    }

    const startMatch = async () => {
        await updateDoc(doc(db, "matches", id), { state: "active" })
        let temp = match
        if (temp === undefined) {
            alert("Unexpected error occured! Please reload the page");
            return
        }
        temp.state = "active"
        setMatch({ ...temp })
        console.log(temp);
    }

    const pauseMatch = async () => {
        await updateDoc(doc(db, "matches", id), { state: "planned" })
        let temp = match
        if (temp === undefined) {
            alert("Unexpected error occured! Please reload the page");
            return
        }
        temp.state = "planned"
        setMatch({ ...temp })
        console.log(temp);
    }

    const keeperSave = async (team, num) => {
        if (keeper[team] === "Brankár") {
            alert("Vyber brankára!")
        } else {
            if (match.saves.find(x => x.keeper === keeper[team])) {
                let temp = match
                if (temp === undefined) {
                    alert("Unexpected error occured! Please reload the page");
                    return
                }
                temp.saves.find(x => x.keeper === keeper[team]).saves += num
                await updateDoc(doc(db, "matches", id), { saves: temp.saves })
                setMatch({ ...temp })

            } else {

                let temp = match
                if (temp === undefined) {
                    alert("Unexpected error occured! Please reload the page");
                    return
                }
                temp.saves.push({ keeper: keeper[team], saves: num, team })
                await updateDoc(doc(db, "matches", id), { saves: temp.saves })
                setMatch({ ...temp })
            }
        }

    }

    const endMatch = async (kontumacia, winner) => {
        setEndMatchPopup(false)
        setPending(true);
        let temp = match
        if (temp === undefined) {
            alert("Unexpected error occured! Please reload the page");
            return
        }
        if (match.state === "active" || kontumacia) {
            if (!kontumacia) {


                //Adding Players
                //#region
                let localPlayers = players
                let countG = []
                let countA = []
                let countR = []

                let countP = []
                let countS = []

                match.goals.forEach(async e => {

                    if (countG.find(x => x.player === e.shooter)) {
                        countG.find(x => x.player === e.shooter).goals++
                    } else {
                        countG.push({ player: e.shooter, goals: 1 })
                    }

                    if (e.assistant) {
                        if (countA.find(x => x.player === e.assistant)) {
                            countA.find(x => x.player === e.assistant).assists++
                        } else {
                            countA.push({ player: e.assistant, assists: 1 })
                        }
                    }

                    if (countR.find(x => x.player === e.keeper)) {
                        countR.find(x => x.player === e.keeper).recieved++
                    } else {
                        countR.push({ player: e.keeper, recieved: 1 })
                    }
                });

                match.punishments.forEach(async p => {
                    if (countP.find(x => x.player === p.player)) {
                        countP.find(x => x.player === p.player).punishments += p.time
                    } else {
                        countP.push({ player: p.player, punishments: p.time })
                    }
                });

                match.saves.forEach(async s => {
                    if (countS.find(x => x.player === s.keeper)) {
                        countS.find(x => x.player === s.keeper).saves += s.saves
                    } else {
                        countS.push({ player: s.keeper, saves: s.saves })
                    }
                })


                countG.forEach(async g => {
                    await updateDoc(doc(db, "players", g.player), { goals: increment(g.goals) })
                    localPlayers.find(x => x.id === g.player).goals += g.goals
                })
                countA.forEach(async a => {
                    await updateDoc(doc(db, "players", a.player), { assists: increment(a.assists) })
                    localPlayers.find(x => x.id === a.player).assists += a.assists
                })
                countR.forEach(async r => {
                    await updateDoc(doc(db, "players", r.player), { recieved: increment(r.recieved) })
                    localPlayers.find(x => x.id === r.player).recieved += r.recieved
                })
                countP.forEach(async p => {
                    await updateDoc(doc(db, "players", p.player), { punishments: increment(p.punishments) })
                    localPlayers.find(x => x.id === p.player).punishments += p.punishments
                })
                countS.forEach(async s => {
                    await updateDoc(doc(db, "players", s.player), { saves: increment(s.saves) })
                    localPlayers.find(x => x.id === s.player).saves += s.saves
                })

                setPlayers(localPlayers)
                //#endregion
            } else {
                temp.score[winner] = 5
                temp.score[1 - winner] = 0
            }
            //Adding Teams
            //#region



            if (temp.score[0] > temp.score[1]) {
                await updateDoc(doc(db, "teams", match.team[0]), {
                    played: increment(1),
                    won: increment(1),
                    points: increment(3),
                    score: [
                        matchTeams[0].score[0] + temp.score[0],
                        matchTeams[0].score[1] + temp.score[1]
                    ]
                })
                await updateDoc(doc(db, "teams", match.team[1]), {
                    played: increment(1),
                    lost: increment(1),
                    score: [
                        matchTeams[1].score[0] + temp.score[1],
                        matchTeams[1].score[1] + temp.score[0]
                    ]
                })
            } else if (temp.score[0] < temp.score[1]) {
                await updateDoc(doc(db, "teams", match.team[0]), {
                    played: increment(1),
                    lost: increment(1),
                    score: [
                        matchTeams[0].score[0] + temp.score[0],
                        matchTeams[0].score[1] + temp.score[1]
                    ]
                })
                await updateDoc(doc(db, "teams", match.team[1]), {
                    played: increment(1),
                    won: increment(1),
                    points: increment(3),
                    score: [
                        matchTeams[1].score[0] + temp.score[1],
                        matchTeams[1].score[1] + temp.score[0]
                    ]
                })

            } else {
                await updateDoc(doc(db, "teams", match.team[0]), {
                    played: increment(1),
                    tie: increment(1),
                    points: increment(1),
                    score: [
                        matchTeams[0].score[0] + temp.score[0],
                        matchTeams[0].score[1] + temp.score[1]
                    ]
                })
                await updateDoc(doc(db, "teams", match.team[1]), {
                    played: increment(1),
                    tie: increment(1),
                    points: increment(1),
                    score: [
                        matchTeams[1].score[0] + temp.score[1],
                        matchTeams[1].score[1] + temp.score[0]
                    ]
                })
            }
            //#endregion


            setEndMatchPopup(false)
            await updateDoc(doc(db, "matches", id), { state: "finished", kontumacia: kontumacia, score: [temp.score[0], temp.score[1]] })
            temp.state = "finished"
            temp.kontumacia = kontumacia
            setMatch({ ...temp })
            window.location.reload(false);
        }


    }



    const [editTime, setEditTime] = useState(false)
    const updateTime = async () => {


        await updateDoc(doc(db, "matches", id), { time: matchTime })
        let temp = match
        temp.time = matchTime
        setMatch({ ...temp })
        setEditTime(false)
    }

    return (
        <div className="overflow-x-hidden">
            <Navbar displaylogo={true} />
            {(!match || !players.length > 0 || !round || !teams || !matchTeams) && <div className="m-40 text-3xl ">Loading...</div>}
            {(match && players.length > 0 && round && teams && matchTeams) && <div className="w-[80vw] relative bg-gray-300 mx-auto rounded-xl">
                <Link to={`/admin/${round.id}`} className="text-primary absolute left-5 top-5 cursor-pointer"><ArrowBackIcon fontSize="large" /></Link>
                <div className="p-10 pb-2 text-primary">
                    <h1 className="text-3xl font-bold text-center">{matchTeams[0].name} vs {matchTeams[1].name}</h1>
                    {match.category === "young" && <h1 className="text-xl font-bold  my-1 text-center">mladší</h1>}
                    {match.category === "old" && <h1 className="text-xl font-bold my-1 text-center">starší</h1>}
                    <hr className="my-5" />
                    <h1 className="text-xl font-bold text-center">{format(new Date(round.date), 'dd.MM.yyyy', { locale: sk })} </h1>
                    {!editTime && <div className="flex text-xl font-bold justify-center items-center">
                        <h1 >{match.time} </h1>
                        <SettingsIcon className="font-normal cursor-pointer ml-3" onClick={e => setEditTime(true)} fontSize="small" />
                    </div>}
                    {editTime && <div className="flex text-lg  justify-center items-center">
                        <input type="time" value={matchTime} onChange={e => setMatchTime(e.target.value)} />
                        <div className='text-white self-center text-center px-2 bg-primary rounded-lg cursor-pointer' onClick={e => { updateTime() }}>Uložiť</div>
                    </div>}

                    <hr className="my-5" />
                    <div className=" flex flex-col items-center justify-center">
                        <div className="items-center text-center">
                            {match.state === "planned" && <label className="text-xl">Status: Plánovaný</label>}
                            {match.state === "active" && <label className="text-xl">Status: Prebieha</label>}
                            {match.state === "finished" && <label className="text-xl">Status: Ukončený</label>}
                            {match.state === "planned" && <div className='text-white mt-2 self-center text-center px-3 py-1 bg-primary rounded-lg cursor-pointer' onClick={e => { startMatch() }}>Spustiť zápas</div>}
                            {match.state === "active" && <div className='text-white mt-2 self-center text-center px-3 py-1 bg-primary rounded-lg cursor-pointer' onClick={e => { pauseMatch() }}>Pozastaviť zápas</div>}
                            {match.state === "active" && <div className='text-white mt-2 self-center text-center px-3 py-1 bg-red-800 rounded-lg font-bold cursor-pointer' onClick={e => { if (!pending) { setEndMatchPopup(true) } }}>
                                {!pending && <span>Ukončiť zápas</span>}
                                {pending && <span>Ukončujem....</span>}
                            </div>}
                            {match.state !== 'active' && <div> Spusti zápas pre jeho úpravu </div>}
                            {match.state !== "finished" && <div className='text-white mt-2 self-center text-center px-3 py-1 bg-[#924226] rounded-lg cursor-pointer' onClick={e => { setKontumPopup(true) }}>Kontumovať</div>}
                        </div>
                    </div>
                    <hr className="my-5" />
                    <div className="flex justify-around">
                        <div className="w-full">
                            <div >
                                <div className="text-3xl text-center">{matchTeams[0].name}</div>
                                <div className="flex justify-center flex-wrap">
                                    {match.goals.filter(x => x.team === 0).map((e, i) => (
                                        <div key={i} className="bg-primary text-white m-1 px-2 rounded-md">
                                            <span className="pr-1">({players.find(x => x.id === e.shooter).number}) {players.find(x => x.id === e.shooter).surname} </span>
                                            {e.assistant && <span>+</span>}
                                            {e.assistant && <span className="pl-1 pr-2">({players.find(x => x.id === e.assistant).number}) {players.find(x => x.id === e.assistant).surname}</span>}
                                            <span className="border-l-2 pl-2 cursor-pointer" onClick={x => deleteGoal(e)}>x</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {match.state === 'active' && <div>
                                <div className="mx-auto flex flex-col items-center">
                                    <div className="text-xl">Gól</div>
                                    <div className="flex py-3">
                                        <select value={shooter} onChange={e => setShooter(e.target.value)}>
                                            <option value="Strelec"> - Strelec gólu -</option>
                                            {players.filter(e => matchTeams[0].players.includes(e.id)).map(e => (
                                                <option key={e.id} value={e.id}>
                                                    ({e.number}){e.name}  {e.surname}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="flex py-3">
                                        <select value={assistant} onChange={e => setAssistant(e.target.value)}>
                                            <option value="Asistencia">- Asistencia -</option>
                                            {players.filter(e => matchTeams[0].players.includes(e.id)).map(e => (
                                                <option key={e.id} value={e.id}>
                                                    ({e.number}){e.name} {e.surname}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='text-white px-3 py-1 mt-4 ml-4 bg-primary rounded-lg cursor-pointer  ' onClick={e => { addGoal(0) }}>Pridať gól</div>
                                </div>
                                <hr className="my-5" />
                                <div className="mx-auto flex justify-between items-center">
                                    <div className="text-xl">Zákroky:</div>
                                    <div className="flex py-3">
                                        <select value={keeper[0]} onChange={e => setKeeper([e.target.value, keeper[1]])}>
                                            <option value="Brankár"> - Brankár - </option>
                                            {players.filter(e => matchTeams[0].players.includes(e.id)).map(e => (
                                                <option key={e.id} value={e.id}>
                                                    {e.position === 'brankár' && "(GK)"}{e.name}  {e.surname}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='text-white px-3 py-1  bg-primary rounded-lg cursor-pointer  ' onClick={e => { keeperSave(0, -5) }}>-5</div>
                                    <div className='text-white px-3 py-1  bg-primary rounded-lg cursor-pointer  ' onClick={e => { keeperSave(0, -1) }}>-1</div>
                                    <div className='text-white px-3 py-1  bg-primary rounded-lg cursor-pointer  ' onClick={e => { keeperSave(0, 0) }}>
                                        {match.saves.find(x => x.keeper === keeper[0]) && <span>({match.saves.find(x => x.keeper === keeper[0]).saves})</span>}
                                        {!(match.saves.find(x => x.keeper === keeper[0])) && <span>(0)</span>}
                                    </div>
                                    <div className='text-white px-3 py-1  bg-primary rounded-lg cursor-pointer  ' onClick={e => { keeperSave(0, 1) }}>+1</div>
                                    <div className='text-white px-3 py-1  bg-primary rounded-lg cursor-pointer  ' onClick={e => { keeperSave(0, 5) }}>+5</div>
                                </div>
                                <hr className="my-5" />
                                <div className="mx-auto flex justify-between items-center">
                                    <div className="text-xl">Tresty:</div>
                                    <div className="flex py-3">
                                        <select value={punishments[0]} onChange={e => setPunishments([e.target.value, punishments[1]])}>
                                            <option value="Hráč"> - Hráč - </option>
                                            {players.filter(e => matchTeams[0].players.includes(e.id)).map(e => (
                                                <option key={e.id} value={e.id}>
                                                    ({e.number}) {e.name}  {e.surname}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label>Minúty: </label>
                                        <input className="w-12 px-2 text-right" value={mins} onChange={e => setMins(e.target.value)} type="number" />

                                    </div>
                                    <div className='text-white px-3 py-1  bg-primary rounded-lg cursor-pointer  ' onClick={e => { addPunishment(0) }}>Pridať trest</div>

                                </div>
                                <div className="flex justify-center flex-wrap">
                                    {match.punishments.filter(x => x.team === 0).map((e, i) => (
                                        <div key={i} className="bg-red-900 text-white m-1 px-2 rounded-md">
                                            {console.log(players)}
                                            <span className="pr-1">({players.find(x => x.id === e.player).number}){players.find(x => x.id === e.player).name} {players.find(x => x.id === e.player).surname} </span>
                                            <span className="pr-1">{e.time}min </span>
                                            <span className="border-l-2 pl-2 cursor-pointer" onClick={x => deletePunishment(e)}>x</span>
                                        </div>
                                    ))}
                                </div>
                            </div>}

                        </div>

                        <div className="">
                            <div className="text-3xl">{match.score[0]}:{match.score[1]}</div>
                        </div>
                        <div className="w-full">
                            <div >
                                <div className="text-3xl text-center">{matchTeams[1].name}</div>
                                <div className="flex justify-center flex-wrap">
                                    {match.goals.filter(x => x.team === 1).map((e, i) => (
                                        <div key={i} className="bg-primary text-white m-1 px-2 rounded-md">
                                            <span className="pr-1">({players.find(x => x.id === e.shooter).number}) {players.find(x => x.id === e.shooter).surname} </span>
                                            {e.assistant && <span>+</span>}
                                            {e.assistant && <span className="pl-1 pr-2">({players.find(x => x.id === e.assistant).number}) {players.find(x => x.id === e.assistant).surname}</span>}
                                            <span className="border-l-2 pl-2 cursor-pointer" onClick={x => deleteGoal(e)}>x</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {match.state === 'active' && <div>
                                <div className="mx-auto flex flex-col items-center">
                                    <div className="text-xl">Gól</div>
                                    <div className="flex py-3">
                                        <select value={shooter} onChange={e => setShooter(e.target.value)}>
                                            <option value="Strelec"> - Strelec gólu -</option>
                                            {players.filter(e => matchTeams[1].players.includes(e.id)).map(e => (
                                                <option key={e.id} value={e.id}>
                                                    ({e.number}){e.name}  {e.surname}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="flex py-3">
                                        <select value={assistant} onChange={e => setAssistant(e.target.value)}>
                                            <option value="Asistencia">- Asistencia -</option>
                                            {players.filter(e => matchTeams[1].players.includes(e.id)).map(e => (
                                                <option key={e.id} value={e.id}>
                                                    ({e.number}){e.name} {e.surname}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='text-white px-3 py-1 mt-4 ml-4 bg-primary rounded-lg cursor-pointer  ' onClick={e => { addGoal(1) }}>Pridať gól</div>
                                </div>
                                <hr className="my-5" />
                                <div className="mx-auto flex justify-between items-center">
                                    <div className="text-xl">Zákroky:</div>
                                    <div className="flex py-3">
                                        <select value={keeper[1]} onChange={e => setKeeper([keeper[0], e.target.value])}>
                                            <option value="Brankár"> - Brankár - </option>
                                            {players.filter(e => matchTeams[1].players.includes(e.id)).map(e => (
                                                <option key={e.id} value={e.id}>
                                                    {e.position === 'brankár' && "(GK)"}{e.name}  {e.surname}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='text-white px-3 py-1  bg-primary rounded-lg cursor-pointer  ' onClick={e => { keeperSave(1, -5) }}>-5</div>
                                    <div className='text-white px-3 py-1  bg-primary rounded-lg cursor-pointer  ' onClick={e => { keeperSave(1, -1) }}>-1</div>
                                    <div className='text-white px-3 py-1  bg-primary rounded-lg cursor-pointer  ' onClick={e => { keeperSave(1, 0) }}>
                                        {match.saves.find(x => x.keeper === keeper[1]) && <span>({match.saves.find(x => x.keeper === keeper[1]).saves})</span>}
                                        {!(match.saves.find(x => x.keeper === keeper[1])) && <span>(0)</span>}
                                    </div>
                                    <div className='text-white px-3 py-1  bg-primary rounded-lg cursor-pointer  ' onClick={e => { keeperSave(1, 1) }}>+1</div>
                                    <div className='text-white px-3 py-1  bg-primary rounded-lg cursor-pointer  ' onClick={e => { keeperSave(1, 5) }}>+5</div>
                                </div>
                                <hr className="my-5" />
                                <div className="mx-auto flex justify-between items-center">
                                    <div className="text-xl">Tresty:</div>
                                    <div className="flex py-3">
                                        <select value={punishments[1]} onChange={e => setPunishments([punishments[0], e.target.value])}>
                                            <option value="Hráč"> - Hráč - </option>
                                            {players.filter(e => matchTeams[1].players.includes(e.id)).map(e => (
                                                <option key={e.id} value={e.id}>
                                                    ({e.number}) {e.name}  {e.surname}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label>Minúty: </label>
                                        <input className="w-12 px-2 text-right" value={mins} onChange={e => setMins(e.target.value)} type="number" />

                                    </div>
                                    <div className='text-white px-3 py-1  bg-primary rounded-lg cursor-pointer  ' onClick={e => { addPunishment(1) }}>Pridať trest</div>

                                </div>
                                <div className="flex justify-center flex-wrap">
                                    {match.punishments.filter(x => x.team === 1).map((e, i) => (
                                        <div key={i} className="bg-red-900 text-white m-1 px-2 rounded-md">
                                            {console.log(players)}
                                            <span className="pr-1">({players.find(x => x.id === e.player).number}){players.find(x => x.id === e.player).name} {players.find(x => x.id === e.player).surname} </span>
                                            <span className="pr-1">{e.time}min </span>
                                            <span className="border-l-2 pl-2 cursor-pointer" onClick={x => deletePunishment(e)}>x</span>
                                        </div>
                                    ))}
                                </div>
                            </div>}

                        </div>

                    </div>
                </div>
            </div>}
            {endMatchPopup &&
                <div className="fixed flex w-screen h-screen top-0 left-0 justify-center items-center text-primary font-bold text-xl">
                    <div className="absolute w-screen h-screen top-0 left-0 bg-black opacity-60"></div>
                    <div className="z-30  bg-gray-300 rounded-lg relative p-8">
                        <h1 className="text-2xl font-bold mb-2 text-primary mt-2">Naozaj chceč ukončiť tento zápas?</h1>
                        <h1 className="text-xl mb-2 text-primary mt-2">Po ukončení zápas nieje možné upravovať ani odstrániť. Všetky výsledky budú zapísané do tabuľky a započítané do štatistiky.</h1>

                        <div className="flex justify-around mt-5 ">
                            <div className='text-white px-3 py-1 bg-primary rounded-lg cursor-pointer mx-auto mt-4' onClick={e => setEndMatchPopup(false)} >Zrušiť</div>
                            <div className='text-white px-3 py-1 bg-red-800 rounded-lg cursor-pointer mx-auto mt-4' onClick={e => endMatch(false, 0)}>Ukončiť zápas</div>
                        </div>

                    </div>
                </div>
            }
            {kontumPopup &&
                <div className="fixed flex w-screen h-screen top-0 left-0 justify-center items-center text-primary font-bold text-xl">
                    <div className="absolute w-screen h-screen top-0 left-0 bg-black opacity-60"></div>
                    <div className="z-30  bg-gray-300 rounded-lg relative p-8">
                        <h1 className="text-2xl font-bold mb-2 text-primary mt-2">Vyber víťaza</h1>
                        <h1 className={"text-3xl font-bold text-center my-10 px-2 cursor-pointer " + (selectedWinner === 0 ? "border-4 rounded-lg  border-primary" : "")} onClick={e => setSelectedWinner(0)}>{matchTeams[0].name}</h1>
                        <h1 className={"text-3xl font-bold text-center my-10 px-2 cursor-pointer " + (selectedWinner === 1 ? "border-4 rounded-lg  border-primary" : "")} onClick={e => setSelectedWinner(1)}>{matchTeams[1].name}</h1>
                        <div className="flex justify-around mt-5 ">
                            <div className='text-white px-3 py-1 bg-primary rounded-lg cursor-pointer mx-auto mt-4' onClick={e => setKontumPopup(false)} >Zrušiť</div>
                            <div className='text-white px-3 py-1 bg-red-800 rounded-lg cursor-pointer mx-auto mt-4' onClick={e => endMatch(true, selectedWinner)}>Potvrdiť</div>
                        </div>

                    </div>
                </div>
            }
        </div>
    );
}

export default EditMatch;