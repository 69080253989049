import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar';
import { useUserAuth } from "../context/UserAuthContext";


import { db } from '../firebase/config';
import { collection, where, query, getDocs } from "firebase/firestore";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('')

    const { signUp } = useUserAuth();
    let navigate = useNavigate();




    const handleSubmit = async (e) => {
        e.preventDefault();
        const q = query(collection(db, "code"), where("secret", "==", code))
        const data = await getDocs(q)
        let a = (data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        if (a.length) {
            try {

                await signUp(email, password);
                navigate("/");
            } catch (err) {
                alert(err.message)
            }
        } else {
            alert("Nesprávny admin kód!")
        }


    };
    return (
        <div className='flex flex-col w-screen h-screen justify-center items-center'>
            <Navbar />
            <form className='flex flex-col border-4 rounded-xl border-accent bg-background px-12 w-96 pb-12' onSubmit={handleSubmit}>
                <h3 className='text-2xl pt-6 pb-12 text-center'>Admin registrácia</h3>

                <div className='flex flex-col'>
                    <label className='text-xl'>Email</label>
                    <input
                        type='email'
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        className='bg-transparent border-b-2 border-accent focus:outline-none'
                    />

                    <label className='text-xl pt-8'>Heslo</label>
                    <input
                        type='password'
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        className='bg-transparent border-b-2 border-accent focus:outline-none'
                    />

                    <label className='text-xl pt-8'>Admin kód</label>
                    <input
                        type='text'
                        onChange={(e) => setCode(e.target.value)}
                        value={code}
                        className='bg-transparent border-b-2 border-accent focus:outline-none'
                    />

                </div>
                <button className='bg-accent text-primary w-18 text-background rounded-md font-semibold px-4 py-2 mt-20 '>Registrácia</button>
            </form>
            <p>Už máš vytvorený účet? <Link to='/login' className="text-accent underline">Prihlás sa!</Link></p>
        </div>
    );
}

export default Login;